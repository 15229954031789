import { Fragment, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'

const navigation = [
  { name: 'home', href: '#home' },
  { name: 'experience', href: '#experience' },
  { name: 'projects', href: '#projects' }
]
const CardProjects = ({ title, imageURL, tech }) => {
  const [hover, setHover] = useState(false);
  return (
    <>
      <div className='relative min-h-[200px] w-[340px] border-2 duration-300 hover:border-indigo-400 border-white rounded-3xl' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <img src={imageURL} alt={`${title} Image`} style={{ zIndex: 1 }} className={`absolute opacity-25 rounded-3xl w-[340px] h-[200px] duration-300 ${hover ? 'md:block' : 'md:hidden'}`} />
        <div className='flex flex-col text-white pl-4 pt-4 relative' style={{ zIndex: 2 }}>
          <div><span className='font-medium text-2xl hover:font-semibold duration-300'>{title}</span></div>
          <div><span className='font-medium text-xl hover:font-semibold duration-300'>Tech Used - <i className='text-indigo-200'>{tech}</i></span></div>
          {/* <div className='pt-4 text-xl text-white'><a target='_blank' href={companyURL}><span className='border-transparent duration-300 hover:text-indigo-200 border-b-2 px-2 font-semibold hover:border-indigo-400'>View Company</span></a></div> */}
        </div>
      </div>
    </>
  )
}
const Card = ({ role, type, company, duration, imageURL, companyURL, tech }) => {
  const [hover, setHover] = useState(false);
  return (
    <>
      <div className='relative min-h-[220px] w-[340px] border-2 duration-300 hover:border-indigo-400 border-white rounded-3xl' onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <img src={imageURL} alt={`${company} Image`} style={{ zIndex: 1 }} className={`absolute opacity-25 rounded-3xl w-[340px] h-[220px] duration-300 ${hover ? 'md:block' : 'md:hidden'}`} />
        <div className='flex flex-col text-white pl-4 pt-4 relative' style={{ zIndex: 2 }}>
          <div><span className='font-medium text-2xl hover:font-semibold duration-300'>{role}</span></div>
          <div><span className='font-medium text-lg hover:font-semibold duration-300'>{type}, {company}</span></div>
          <div><span className='font-medium text-xl hover:font-semibold duration-300'>Tech Used - <i className='text-indigo-200'>{tech}</i></span></div>
          <div><span className='font-normal text-xl'>{duration}</span></div>
          <div className='pt-4 text-xl text-white'><a target='_blank' href={companyURL}><span className='border-transparent duration-300 hover:text-indigo-200 border-b-2 px-2 font-semibold hover:border-indigo-400'>View Company</span></a></div>
        </div>
      </div>
    </>
  )
}

export default function Example({ state, setState }) {
  const [view, setView] = useState('home');
  const [popover, setPopover] = useState(false);
  // return (
  //   <>
  //     <div className='overflow-hidden mx-auto'>
  //       <div className='bg-black h-[825px] w-[373px] overflow-auto'>
  //         <div className='flex justify-evenly items-center flex-col min-h-full'>
  //           <div>
  //             <div className='text-white font-semibold text-3xl' onClick={() => {console.log("clicked name");setState(1)}}>Aarya Gowda</div>
  //             <div className='flex justify-evenly mt-2 h-auto py-3'>
  //               <a href='https://github.com/Itz-MrJ/'><img src='/github.png' width={36} height={36} className='bg-white rounded-full' /></a>
  //               <a href='https://www.linkedin.com/in/aarya-gowda-95121a248/'><img src='/linkedin.png' width={36} height={36} /></a>
  //               <div>
  //                 <span class="animate-ping absolute inline-flex h-[10px] w-[10px] rounded-full bg-sky-400"></span>
  //                 <a href='mailto:aarya2003gowda@gmail.com'><img src='/mail.png' width={36} height={36} /></a>
  //               </div>
  //             </div>
  //           </div>
  //           <img src='/refresh.png' width={126} height={126} className=' animate-spin-slow' />
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // )
  return (
    <div className="relative overflow-hidden bg-gray-900">
      <Popover as="header" className="relative">
        <div className={`duration-300 pt-6 p-4 ${popover ? 'bg-white' : 'bg-gray-900'}`}>
          <nav className="relative max-w-7xl mx-auto flex items-center justify-between px-6" aria-label="Global">
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full">
                <a href="#">
                  <span className="sr-only">Workflow</span>
                  <img className="w-auto h-10" src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg" alt="" />
                </a>
                <div className="-mr-2 flex items-center">
                  <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white" onClick={() => { console.log("clickeddddd"); setPopover(!popover) }}>
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" onClick={() => console.log("clicked menu")} />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-8">
                {navigation.map((item) => (
                  <div key={item.name} className="text-base font-medium text-white hover:text-gray-300 border-2 border-transparent hover:border-black">
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="hidden">
              <a href="#" className="text-base font-medium text-white hover:text-gray-300">
                Log in
              </a>
              <a
                href="#"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700">
                Start free trial
              </a>
            </div>
          </nav>
        </div>
        <div className={`absolute flex flex-col justify-evenly w-[380px] rounded-b-3xl min-h-[190px] bg-white duration-300 z-20 ${popover ? 'top-[75px]' : '-top-[200px]'}`}>
          {
            navigation.map((item) => (
              <div key={item.name} className="text-2xl font-semibold w-[370px] flex justify-center"><span className='border-b-2 border-transparent hover:border-black px-2 duration-300 capitalize pb-1' onClick={(e) => {
                setPopover(false);
                if (view == item.name) return;
                var old = document.getElementById(view);
                old.style.transform = 'translateX(400px)';
                setTimeout(() => {
                  console.log("timeout done", item.name);
                  setView(item.name);
                  var newone = document.getElementById(item.name);
                  old.classList.add('hidden');
                  newone.classList.remove('hidden');
                  newone.style.transform = 'translateX(0px)';
                  old.style.transform = 'translateX(-400px)';
                  console.log(newone, old);
                }, 500);
                // setView(item.name);
              }}>
                {item.name}
              </span>
              </div>
            ))
          }
          {/* <div className='text-2xl font-semibold w-[370px] flex justify-center'><span className='border-b-2 border-transparent hover:border-black px-2 duration-300' onClick={handleHome('home')}>Home</span></div>
          <div className='text-2xl font-semibold w-[370px] flex justify-center'><span className='border-b-2 border-transparent hover:border-black px-2 duration-300' onClick={handleHome('experience')}>Experience</span></div>
          <div className='text-2xl font-semibold w-[370px] flex justify-center'><span className='border-b-2 border-transparent hover:border-black px-2 duration-300' onClick={handleHome('projects')}>Projects</span></div>
          <div className='text-2xl font-semibold w-[370px] flex justify-center'><span className='border-b-2 border-transparent hover:border-black px-2 duration-300' onClick={handleHome('downloadresume')}>Download Resume</span></div>
          <div></div> */}
        </div>
      </Popover>

      <main id='home' className='duration-500'>
        <div className="pt-10 bg-gray-900 pt-16">
          <div className={`mx-auto max-w-7xl duration-700 min-h-[681px]`}>
            <div className="">
              <div className="mx-auto max-w-md px-4 max-w-2xl px-6 text-center">
                <div className="">
                  <h1 className="tracking-tight font-extrabold text-white text-6xl">
                    <span className="block">Aarya</span>
                    <span className="block text-indigo-400">Gowda</span>
                  </h1>
                  <p className="mt-2 text-gray-300 text-xl">
                    Hi there, I am a Full Stack Developer and a Freelancer based in India!<br></br>I have successfully delivered products to various clients <span className='text-indigo-400 font-medium'>worldwide.</span>
                  </p>
                </div>
              </div>
              <div className="mt-12">
                <div className="mx-auto max-w-2xl px-6 flex justify-around">
                  <div className='text-white text-xl font-semibold border-2 rounded-3xl duration-300 border-transparent hover:border-violet-400 p-4' onClick={() => {
                    var old = document.getElementById(view);
                    old.style.transform = 'translateX(400px)';
                    setTimeout(() => {
                      setView("experience");
                      var newone = document.getElementById("experience");
                      old.classList.add('hidden');
                      newone.classList.remove('hidden');
                      newone.style.transform = 'translateX(0px)';
                      old.style.transform = 'translateX(-400px)';
                    }, 500);
                  }}>Experience</div>
                  <div className='text-white text-xl font-semibold border-2 rounded-3xl duration-300 border-transparent hover:border-violet-400 p-4' onClick={() => {
                    var old = document.getElementById(view);
                    old.style.transform = 'translateX(400px)';
                    setTimeout(() => {
                      setView("projects");
                      var newone = document.getElementById("projects");
                      old.classList.add('hidden');
                      newone.classList.remove('hidden');
                      newone.style.transform = 'translateX(0px)';
                      old.style.transform = 'translateX(-400px)';
                    }, 500);
                  }}>Projects</div>
                </div>
                <div className='mx-auto max-w-2xl px-6 flex justify-around mt-4'>
                  <div className={`text-white text-xl font-semibold border-2 duration-300 border-transparent glowhover`} onClick={() => setState(1)}>Tech & Tools</div>
                </div>
              </div>
              <div className='mt-12'>
                <div className="mx-auto max-w-2xl px-6 flex justify-around">
                  <a target='_blank' href='https://github.com/Itz-MrJ/' className='singleglow'><img src='/github.png' width={36} height={36} /></a>
                  <a target='_blank' href='https://www.linkedin.com/in/aarya-gowda-95121a248/' className='singleglow'><img src='/linkedin.png' width={36} height={36} /></a>
                  <a target='_blank' href='https://www.instagram.com/aaryagowda_/' className='singleglow'><img src='/instagram.png' width={36} height={36} /></a>
                  <div className='singleglow'>
                    <span class="animate-ping absolute inline-flex h-[10px] w-[10px] rounded-full bg-sky-400"></span>
                    <a target='_blank' href='mailto:aarya2003gowda@gmail.com'><img src='/mail.png' width={36} height={36} /></a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="mx-auto max-w-md px-4 max-w-2xl px-6" style={{ height: 220 }} /> */}

            {/* <div>
              <div className="relative">
                <div className="absolute inset-x-0 bottom-0 h-1/2" />
                <div className="max-w-7xl mx-auto px-6">
                  <div className="relative shadow-xl rounded-2xl overflow-hidden">
                    <div className="absolute inset-0">
                      <img
                        className="h-full w-full object-cover"
                        src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                        alt="People working on laptops"
                      />
                      <div className="absolute inset-0 bg-indigo-700 mix-blend-multiply" />
                    </div>
                    <div className="relative px-4 py-16 px-6 py-24">
                      <h1 className="text-center text-4xl font-extrabold tracking-tight text-5xl">
                        <span className="block text-white">Take control of your</span>
                        <span className="block text-indigo-200">customer support</span>
                      </h1>
                      <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 max-w-3xl">
                        Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat
                        aliqua.
                      </p>
                      <div className="mt-10 max-w-sm mx-auto max-w-none flex justify-center">
                        <div className="space-y-0 space-y-0 mx-auto inline-grid grid-cols-2 gap-5">
                          <a
                            href="#"
                            className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 px-8">
                            Get started
                          </a>
                          <a
                            href="#"
                            className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 bg-opacity-60 hover:bg-opacity-70 px-8">
                            Live demo
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </main>
      <main id='experience' className={`duration-500 hidden`} style={{ transform: 'translateX(-400px)' }}>
        <div className="bg-gray-900 pt-16">
          <div className={`mx-auto max-w-7xl duration-700 min-h-[681px]`}>
            <div className="text-center text-white text-3xl font-bold">
              <span className='px-2 pb-1 duration-300 border-b-2 border-transparent hover:border-white'>
                Experience
              </span>
            </div>
            <div className='flex flex-col items-center mt-9 pb-9'>
              <Card role={"Full Stack Developer"} type={"Intern"} company={"ISRO"} companyURL={"https://www.isro.gov.in/"} imageURL={"/isro.webp"} duration={"Nov 2023 - Present"} tech={"React.js, Next.js, Vercel & Postgres"} />
              <br></br>
              <Card role={"Full Stack Developer"} type={"Part Time"} company={"WIN Research"} companyURL={"https://www.winresearchcentre.in/"} imageURL={"/wrc.jfif"} duration={"Dec 2023 - Feb 2024"} tech={"React.js, Next.js, MongoDB, Firebase & Vercel"} />
              <br></br>
              <Card role={"Full Stack Developer"} type={"Intern"} company={"WIN Research"} companyURL={"https://www.winresearchcentre.in/"} imageURL={"/wrc.jfif"} duration={"October 2023 - Nov 2023"} tech={" Android Studio, Java, XML, OpenAI API & Firebase"} />
            </div>
          </div>
        </div>
      </main>
      <main id='projects' className={`duration-500 hidden`} style={{ transform: 'translateX(-400px)' }}>
        <div className="bg-gray-900 pt-16">
          <div className={`mx-auto max-w-7xl duration-700 min-h-[681px]`}>
            <div className="text-center text-white text-3xl font-bold">
              <span className='px-2 pb-1 duration-300 border-b-2 border-transparent hover:border-white'>
                Projects
              </span>
            </div>
            <div className='flex flex-col items-center mt-9 pb-9'>
              <CardProjects title={"Discord Bots"} imageURL={"/discord.jpg"} tech={"Python, Node.js, Heroku, Replit & MongoDB"} />
              <br></br>
              <CardProjects title={"AI Based University Finder"} imageURL={"/chatgpt.jpg"} tech={"React.js, Next.js, MongoDB, OpenAI API & Express"} />
              <br></br>
              <CardProjects title={"Food Ordering"} imageURL={"/food.jpg"} tech={"React.js, Next.js & MongoDB"} />
              <br></br>
              <CardProjects title={"URL shortener"} imageURL={"/url.jpg"} tech={"Next.js & MongoDB"} />
              <br></br>
              <CardProjects title={"Bingo"} imageURL={"/bingo.jpg"} tech={"Java & Sockets"} />
              <br></br>
            </div>
          </div>
        </div>
      </main>
    </div>
  )

  return (
    <div className="relative overflow-hidden">
      <Popover as="header" className="relative min-h-[80px]">
        <div className="bg-gray-900 pt-6 p-4">
          <nav className="relative max-w-7xl mx-auto flex items-center justify-between px-6" aria-label="Global">
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full">
                <a href="#">
                  <span className="sr-only">Workflow</span>
                  <img className="w-auto h-10" src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg" alt="" />
                </a>
                <div className="-mr-2 flex items-center">
                  <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-8">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} className="text-base font-medium text-white hover:text-gray-300">
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
            <div className="hidden">
              <a href="#" className="text-base font-medium text-white hover:text-gray-300">
                Log in
              </a>
              <a
                href="#"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700">
                Start free trial
              </a>
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95">
          <Popover.Panel focus className="absolute z-10 top-0 inset-x-0 p-4 transition transform origin-top">
            <div className="rounded-3xl shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <a key={item.name} href={item.href} className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50">
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-6 px-5">
                  <a href="#" className="block text-center w-full py-3 px-4 rounded-md shadow bg-indigo-600 text-white font-medium hover:bg-indigo-700">
                    Start free trial 😏😏😏
                  </a>
                </div>
                <div className="mt-6 px-5">
                  <p className="text-center text-base font-medium text-gray-500">
                    Existing customer?{' '}
                    <a href="#" className="text-gray-900 hover:underline">
                      Login brooooooo
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      <main>
        <div className="pt-10 bg-gray-900 min-h-[744px]">
          <div className="mx-auto max-w-7xl">
            <div className="">
              <div className="mx-auto max-w-2xl px-6 text-center">
                <div className="">
                  {/* <a href="#" className="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 text-base hover:text-gray-200">
                    <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-indigo-500 rounded-full">
                      We're hiring
                    </span>
                    <span className="ml-4 text-sm">Visit our careers page</span>
                    <ChevronRightIcon className="ml-2 w-5 h-5 text-gray-500" aria-hidden="true" />
                  </a> */}
                  {/* mt-4 mt-5 */}
                  <h1 className="tracking-tight font-extrabold text-white text-6xl">
                    <span className="block">Aarya</span>
                    <span className="block text-indigo-400">Gowda</span>
                  </h1>
                  <p className="mt-2 text-gray-300 text-xl">
                    Hi there, I am a Full Stack Developer and a Freelancer based in India! I have successfully delivered products along with my team to various clients worldwide.
                  </p>
                  <div className=" mt-12">
                    <form action="#" className="max-w-xl mx-auto">
                      <div className="flex">
                        <div className="min-w-0 flex-1">
                          <label htmlFor="email" className="sr-only">
                            Email address
                          </label>
                          <input
                            id="email"
                            type="email"
                            placeholder="Enter your email"
                            className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900"
                          />
                        </div>
                        <div className="mt-0 ml-3">
                          <button
                            type="submit"
                            className="block w-full py-3 px-4 rounded-md shadow bg-indigo-500 text-white font-medium hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300 focus:ring-offset-gray-900">
                            Start free trial
                          </button>
                        </div>
                      </div>
                      <p className="mt-3 text-sm text-gray-300 mt-4">
                        Start your free 14-day trial, no credit card necessary. By providing your email, you agree to our{' '}
                        <a href="#" className="font-medium text-white">
                          terms of service
                        </a>
                        .
                      </p>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-12 -mb-48">
                <div className="mx-auto max-w-md px-4 max-w-2xl px-6">
                  {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                  <img className="w-full" src="https://tailwindui.com/img/component-images/cloud-illustration-indigo-400.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="mx-auto max-w-md px-4 max-w-2xl px-6" style={{ height: 220 }} />

            {/* <div>
              <div className="relative">
                <div className="absolute inset-x-0 bottom-0 h-1/2" />
                <div className="max-w-7xl mx-auto px-6">
                  <div className="relative shadow-xl rounded-2xl overflow-hidden">
                    <div className="absolute inset-0">
                      <img
                        className="h-full w-full object-cover"
                        src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                        alt="People working on laptops"
                      />
                      <div className="absolute inset-0 bg-indigo-700 mix-blend-multiply" />
                    </div>
                    <div className="relative px-4 py-16 px-6 py-24">
                      <h1 className="text-center text-4xl font-extrabold tracking-tight text-5xl">
                        <span className="block text-white">Take control of your</span>
                        <span className="block text-indigo-200">customer support</span>
                      </h1>
                      <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 max-w-3xl">
                        Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat
                        aliqua.
                      </p>
                      <div className="mt-10 max-w-sm mx-auto max-w-none flex justify-center">
                        <div className="space-y-0 space-y-0 mx-auto inline-grid grid-cols-2 gap-5">
                          <a
                            href="#"
                            className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 px-8">
                            Get started
                          </a>
                          <a
                            href="#"
                            className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 bg-opacity-60 hover:bg-opacity-70 px-8">
                            Live demo
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </main>
    </div>
  )
}
