import * as THREE from 'three'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Html, Mask, useMask, OrthographicCamera, Clone, Float as FloatImpl } from '@react-three/drei'
import useSpline from '@splinetool/r3f-spline'
import Embed from './Embed'
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter'

// function getRandomNumber(min, max, float = false) {
//   if (float) {
//     return Math.random() * (max - min) + min;
//   } else {
//     min = Math.ceil(min); // Round up to the nearest integer
//     max = Math.floor(max); // Round down to the nearest integer
//     return Math.floor(Math.random() * (max - min + 1)) + min;
//   }
// }


export function App() {
  const container = useRef()
  const domContent = useRef()
  useEffect(() => {
    document.title = 'Aarya Gowda | Portfolio';
    var arr = [['url', 'https://aarya.fun/'], ['theme-color', '#000000'], ['description', 'Full Stack Developer | Freelancer'], ['keywords', 'aarya, aarya gowda, gowda, dayananda sagar, aarya portfolio, aarya gowda portfolio, portfolio']]
    arr.forEach(v => {
      const meta = document.createElement('meta');
      meta.name = v[0];
      meta.content = v[1];
      document.head.appendChild(meta);
    })
  }, [])
  return (
    <div ref={container} className="content-container">
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden' }} ref={domContent} />
      <Canvas
        shadows
        flat
        linear
        style={{ pointerEvents: 'none' }}
        eventSource={container}
        eventPrefix="page">
        <directionalLight castShadow intensity={0.4} position={[-10, 50, 300]} shadow-mapSize={[512, 512]} shadow-bias={-0.002}>
          <orthographicCamera attach="shadow-camera" args={[-2000, 2000, 2000, -2000, -10000, 10000]} />
        </directionalLight>
        <OrthographicCamera makeDefault={true} far={100000} near={-100000} position={[0, 0, 1000]} />
        <hemisphereLight intensity={0.3} color="#eaeaea" position={[0, 1, 0]} />
        <Scene portal={domContent} position={[0, -50, 0]} />
        <Test />
      </Canvas>
    </div>
  )
}

function Test() {
  const scene = useThree((state) => state.scene)
  const exporter = new GLTFExporter()

  // Parse the input and generate the glTF output
  exporter.parse(
    scene,
    // called when the gltf has been generated
    (gltf) => {
      console.log(JSON.stringify(gltf))

      /*var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(gltf))
      var dlAnchorElem = document.getElementById('downloadAnchorElem')
      dlAnchorElem.setAttribute('href', dataStr)
      dlAnchorElem.setAttribute('download', 'scene.json')
      dlAnchorElem.click()*/

      //downloadJSON(gltf)
    },
    {},
  )
}

function Scene({ portal, ...props }) {
  const [state, setState] = useState(0);
  const [index, setIndex] = useState(0);
  const [balls, setBalls] = useState(-1);
  const objRef = useRef();
  let timeout = null;
  const v = new THREE.Vector3();
  const wheel = useRef(0);
  const hand = useRef();
  const [clicked, click] = useState(false);
  const { nodes, materials } = useSpline('main.spline');
  useEffect(() => {
    if (state == 1) {
      var iter = 0;
      function iterateWithInterval() {
        var arr = [{
          name: 'lang-js',
          position: [150, -20, 0],
          initialPosition: [-window.innerWidth, window.innerHeight, 0]
        }, {
          name: 'lang-py',
          position: [150, -20, 0],
          initialPosition: [-window.innerWidth, window.innerHeight, 0]
        }];
        if (iter < arr.length) {
          console.log(arr[iter]);
          iter++;
          setTimeout(iterateWithInterval, 1000);
        }
      }
      iterateWithInterval();
    }
  }, [state]);

  useFrame((state) => {
    v.copy({ x: state.pointer.x, y: state.pointer.y, z: 0 });
    v.unproject(state.camera);
    hand.current.rotation.x = THREE.MathUtils.lerp(hand.current.rotation.x, clicked ? -0.7 : -0.5, 0.2);
    hand.current.position.lerp({ x: v.x - 100, y: wheel.current + v.y, z: v.z }, 0.4);
    state.camera.zoom = THREE.MathUtils.lerp(state.camera.zoom, clicked ? 0.9 : 0.7, clicked ? 0.025 : 0.15);
    state.camera.position.lerp({ x: -state.pointer.x * 400, y: -state.pointer.y * 200, z: 1000 }, 0.1);
    state.camera.lookAt(0, 0, 0);
    state.camera.updateProjectionMatrix();
  });

  return (
    <group {...props} dispose={null} ref={objRef}>
      <FloatFixed object={nodes['Bg-stuff']} />
      <FloatFixed object={nodes['card1']} />
      <FloatFixed object={nodes['handle2']} />
      <FloatFixed object={nodes['folder3']} />
      <FloatFixed object={nodes['trophy4']} />
      <FloatFixed object={nodes['Icon-text-2']} />
      <FloatFixed object={nodes['Icon-like']} />
      <FloatFixed object={nodes['Icon-star']} />
      <FloatFixed object={nodes['Icon-play']} />
      <FloatFixed object={nodes['Icon-text-1']} />
      {/* {
        balls >= 0 ? 
        <Float object={nodes['lang-js']} position={[150,-20,0]} initialPosition={[-window.innerWidth,window.innerHeight,0]}/> 
        : balls >= 1 ?
        <Float object={nodes['lang-py']} position={[150,-20,0]} initialPosition={[-window.innerWidth,window.innerHeight,0]}/> : ""
      } */}
      {/* <Float object={nodes['lang-java']} position={[150,-20,0]} initialPosition={[-window.innerWidth,window.innerHeight,0]}/>  */}
      <group ref={hand}>
        <Clone object={nodes['hand-r']} rotation-y={0.35} />
      </group>
      <Clone object={nodes['Bubble-BG']} scale={0.3} position={[380, -100, 0]} />
      <Clone object={nodes['Bubble-BG']} scale={0.33} position={[-400, -100, 0]} />
      <Clone object={nodes['Bubble-BG']} scale={0.4} position={[-420, 200, 0]} />
      <FloatImpl floatIntensity={100} rotationIntensity={0.5} speed={1}>
        <FloatFixed intensity={100} rotation={0.5} object={nodes['Bubble-LOGO']} position={[370, 400, 0]} scale={1} />
        <group position={[0, -50, 0]} rotation={[-0.15, 0, 0]}>
          {/* <Clone object={nodes['hand-l']} position={[80, 100, -150]} /> */}
          <group name="phone" position={[-50, 20, -68]}>
            {/* <Clone object={[nodes['Rectangle 4'], nodes['Rectangle 3'], nodes['Boolean 2']]} /> */}
            <Mask id={1} colorWrite={false} depthWrite={false} geometry={nodes.screen.geometry} castShadow receiveShadow position={[0, 0, 9.89]}>
              <Html className="content-embed" portal={portal} scale={40} transform zIndexRange={[-1, 0]}>
                <Embed state={state} setState={setState} />
              </Html>
            </Mask>
            <mesh
              onWheel={(e) => {
                wheel.current = -e.deltaY / 2
                clearTimeout(timeout)
                timeout = setTimeout(() => (wheel.current = 0), 100)
              }}
              onPointerDown={(e) => {
                e.target.setPointerCapture(e.pointerId)
                click(true)
              }}
              onPointerUp={(e) => {
                e.target.releasePointerCapture(e.pointerId)
                click(false)
              }}
              receiveShadow
              geometry={nodes.screen.geometry}>
              <meshStandardMaterial transparent opacity={0.1} />
            </mesh>
          </group>
        </group>
      </FloatImpl>
    </group>
  )
}

const Float = ({ object, intensity = 300, speed = 0.02, rotation = 1, position, initialPosition = [0, 0, 0], ...props }) => {
  const floatRef = useRef();
  const [animatedPosition, setAnimatedPosition] = useState({ x: initialPosition[0], y: initialPosition[1], z: initialPosition[2] })
  const targetPosition = position;
  useFrame(() => {
    if (floatRef.current) {
      // Calculate movement towards the target position with specified speed
      const newPosition = {
        x: THREE.MathUtils.lerp(floatRef.current.position.x, targetPosition[0], speed),
        y: THREE.MathUtils.lerp(floatRef.current.position.y, targetPosition[1], speed),
        z: THREE.MathUtils.lerp(floatRef.current.position.z, targetPosition[2], speed),
      }
      setAnimatedPosition(newPosition)
    }
  })
  return (
    <group ref={floatRef} position={[animatedPosition.x, animatedPosition.y, animatedPosition.z]}>
      <FloatImpl floatIntensity={intensity} rotationIntensity={rotation} speed={2}>
        <Clone object={object} {...props} />
      </FloatImpl>
    </group>
  )
}

const FloatFixed = ({ object, intensity = 300, rotation = 1, ...props }) => (
  <FloatImpl floatIntensity={intensity} rotationIntensity={rotation} speed={2}>
    <Clone object={object} {...props} />
  </FloatImpl>
)